import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { firstValueFrom, Subject } from "rxjs";
import { environment } from 'src/environments/environment'
import { AuthService } from "@app/shared/services/auth.service";

export interface RestResponse {
  code: string;
  error: string;
  message: string;
  payload: Record<string, any>;
}

export interface RemoteVersion {
  version: string;
}

@Injectable({
  providedIn: 'root'
})
export class RestService {
  static readonly CURRENT_APP_SETTINGS_PATH = 'stored-jsons/get-mcp-app-settings';
  static readonly TRANSLATION_PATH = 'stored-jsons/get-translation';
  static readonly APP_SETTINGS_ID = 'mcp_settings';
  errorUpdate: Subject<unknown> = new Subject<unknown>();

  constructor(
    private readonly _http: HttpClient,
    private readonly _authService: AuthService
  ) {}

  async getCurrentAppVersion() {
    try {
      const settings = await this.post(RestService.CURRENT_APP_SETTINGS_PATH) as RemoteVersion;
      return settings.version;
    } catch (error) {
      throw new Error(error as unknown as string);
    }
  }

  async getTranslation(versionId: string) {
    try {
      const json = await this.post(RestService.TRANSLATION_PATH, {versionId, realm: 'mcp'});
      return json;
    } catch (error) {
      throw new Error(error as unknown as string);
    }
  }

  async post(url: string, body?:any, isApiCall = true) {
    let authToken = '';
    const token = this._authService.getToken();
    if (token) {
      authToken = 'Bearer ' + token;
    }

    const headers = new HttpHeaders()
    .set('content-type', 'application/json; charset=UTF-8')
    .set('x-api-key', environment.API_KEY)
    .set('x-client-id', environment.API_CLIENT_ID)
    .set('Authorization', authToken);
  
    const fullUrl = isApiCall ? environment.API_URL + url : url;
    try {
      console.log('[RestService] POST request: ', url, body);
      const result: RestResponse = await firstValueFrom(this._http.post(fullUrl, body, {headers})) as unknown as RestResponse;
      console.log('[RestService] POST response: ', result);
      if (result?.error === '200') {
        if (result.payload['json']) {
          const jsonString: string = result.payload['json'];
          const json = JSON.parse(jsonString) as unknown;
          return json;
        } else {
          return result.payload;
        }
      } else {
        throw new Error('Server error: ' + result ? result.error : 'unknown error');
      }
    } catch (error: unknown) {
      console.log('[RestService] POST - error: ', error);
      this.errorUpdate.next(error);
      throw error;
    }
  }
}
